import { Icon } from 'semantic-ui-react';

import { LimitedRichTextCell } from 'components/ui/Text';

import * as svars from 'assets/style/variables';

const renderHtml = (title, icon = null, prefix = null, key = null) => {
  const richTextElement = <LimitedRichTextCell value={title} key={`lm-${key}`} />;
  if (!icon && !prefix) {
    return richTextElement;
  }
  return (
    <div key={key} style={{ display: 'flex' }}>
      {icon ? (
        <Icon
          style={{
            color: svars.accentColorClear,
            marginRight: svars.spaceNormal,
          }}
          name={icon}
        />
      ) : null}
      {prefix ? (
        <span
          style={{
            marginRight: svars.spaceNormal,
          }}
        >
          {prefix}
        </span>
      ) : null}
      {richTextElement}
    </div>
  );
};

export default renderHtml;
