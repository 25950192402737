import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { maybeSetAnalyticsUser } from 'actions/user';

import amplitude from 'amplitude-js';

import config from 'config';

export default function useInterval(callback, delay) {
  const intervalRef = useRef(null);
  const savedCallback = useRef(callback);
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);
  useEffect(() => {
    const tick = () => savedCallback.current();
    intervalRef.current = window.setInterval(tick, delay);
    return () => window.clearInterval(intervalRef.current);
  }, [delay]);
  return intervalRef;
}

export function useOnClickOutside(handler) {
  const ref = useRef(null);
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);
      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new
    // function on every render that will cause this effect
    // callback/cleanup to run every render. It's not a big deal
    // but to optimize you can wrap handler in useCallback before
    // passing it into this hook.
    [ref, handler]
  );
  return ref;
}

export function useDebounce(value, delay = 100) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Set debouncedValue to value (passed in) after the specified delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    // Only re-call effect if value changes
    [value]
  );

  return debouncedValue;
}

export function useThrottle(value, interval = 500) {
  const [throttledValue, setThrottledValue] = useState(value);
  const lastExecuted = useRef(Date.now());

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (Date.now() >= lastExecuted.current + interval) {
      lastExecuted.current = Date.now();
      setThrottledValue(value);
    } else {
      const timerId = setTimeout(() => {
        lastExecuted.current = Date.now();
        setThrottledValue(value);
      }, interval);

      return () => clearTimeout(timerId);
    }
  }, [value, interval]);

  return throttledValue;
}

/**
 * @param {clickEvent} doubleClick
 * @param {clickEvent} [click]
 * @param {UseDoubleClickOptions} [options]
 * @returns {clickEvent}
 */
export const useDoubleClick = (
  doubleClick,
  click,
  options = { timeout: 300 }
) => {
  const clickTimeout = useRef();

  const clearClickTimeout = () => {
    if (clickTimeout) {
      clearTimeout(clickTimeout.current);
      clickTimeout.current = null;
    }
  };

  return useCallback(
    (event, otherProps) => {
      clearClickTimeout();
      if (click && event.detail === 1) {
        clickTimeout.current = setTimeout(() => {
          click(event, otherProps);
        }, options.timeout);
      }
      if (event.detail % 2 === 0) {
        doubleClick(event, otherProps);
      }
    },
    [click, doubleClick, options.timeout]
  );
};

export function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component
        {...props}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  }

  return ComponentWithRouterProp;
}

export function useMemoizedFactorySelector(selectorFactory, ...params) {
  const memoizedSelector = useMemo(() => selectorFactory(...params), params);
  return useSelector(memoizedSelector);
}

export function useKeyPress(targetKey, callback) {
  const upHandler = ({ key }) => {
    if (key === targetKey) {
      callback();
    }
  };
  // Add event listeners
  useEffect(() => {
    window.removeEventListener('keyup', upHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler);
    };
  }, [callback]);
}

/**
 * Hook to track page views.
 *
 */
const usePageTracking = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(maybeSetAnalyticsUser());
  }, []);
  useEffect(() => {
    // Log to GA
    if (config.ga.property) {
      const pageUrl = window.location.pathname + window.location.search;
      ReactGA.set({ page: pageUrl });
      ReactGA.pageview(pageUrl);
    }

    // Log to Amplitude
    if (config.amplitude.key) {
      amplitude.getInstance().logEvent('page view', {
        path: location.pathname,
        search: location.search,
      });
    }
  }, [location]);
};
export function PageTracker() {
  usePageTracking();
  // Uncomment to log all page changes
  // const { pathname } = useLocation();
  // useEffect(() => {
  //   console.log('pathname is', pathname);
  // }, [pathname]);
  return null;
}

export /**
 * Fetch visualizations aggregates based on a facet id and an aggregate type.
 *
 * @param {Any} an optional default active filter index
 */ const useToggler = (defaultActiveFilterIndex) => {
  const [activeFilters, setActiveFilters] = useState(
    defaultActiveFilterIndex ? [defaultActiveFilterIndex] : []
  );

  const onToggle = useCallback(
    (filterName) => () =>
      setActiveFilters(
        activeFilters.includes(filterName)
          ? activeFilters.filter((item) => item !== filterName)
          : [...activeFilters, filterName]
      ),
    [setActiveFilters, activeFilters]
  );

  return [(filterName) => activeFilters.includes(filterName), onToggle];
};
