import { useCallback } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { t } from '@lingui/macro';

import { createSelector } from '@reduxjs/toolkit';

export const EMPTY_CATEGORY_LABEL = '_empty_';
export const addEmptyItem = (items) => [
  {
    key: 'empty',
    value: EMPTY_CATEGORY_LABEL,
    label: `(${t`not-completed`})`,
  },
  ...items,
];

const identity = (id) => id;
const makeDropdownItemsFromDbDocuments = (documents) =>
  documents?.map(({ id, name }) => ({
    key: id,
    value: id,
    label: name,
  }));

export const useFormatter = (
  entitiesSelector,
  labelFormatter,
  accessor = identity
) => {
  const entities = useSelector(entitiesSelector, shallowEqual);
  const formatter = useCallback(
    (...item) => {
      const id =
        typeof accessor === 'string' ? item?.[accessor] : accessor(...item);
      return (
        (id === EMPTY_CATEGORY_LABEL && t`not-completed`) ||
        (id && entities && labelFormatter(entities, id)) ||
        id ||
        '-' // Fallback to id if no label is found
      );
    },
    [entities]
  );
  return formatter;
};

// Static fields
// Ontology
const ontologiesSelector = (state) => state.entities.ontologies;
const ontologyLabelFormatter = (ontologies, id) =>
  ontologies?.[id]?.ontology_name;
export const useOntologyLabelFormatter = (accessor) =>
  useFormatter(ontologiesSelector, ontologyLabelFormatter, accessor);

// Concept
const conceptsSelector = (state) => state.entities.concepts;
const conceptLabelFormatter = (concepts, id) => concepts?.[id]?.name;
const conceptParentLabelFormatter = (concepts, id) =>
  conceptLabelFormatter(concepts, concepts?.[id]?.parent?.id);
export const useConceptLabelFormatter = (accessor = identity) =>
  useFormatter(conceptsSelector, conceptLabelFormatter, accessor);
export const useConceptParentLabelFormatter = (accessor) =>
  useFormatter(conceptsSelector, conceptParentLabelFormatter, accessor);
export const useConceptLevelFormatter = (accessor) =>
  useFormatter(
    conceptsSelector,
    (concepts, id) => concepts?.[id]?.level,
    accessor
  );

export const fromConceptOntologyLabelFormatter = createSelector(
  conceptsSelector,
  ontologiesSelector,
  (concepts, ontologies) => (conceptId) =>
    ontologies?.[concepts?.[conceptId]?.ontologyId].name
);

// Source
export const sourcesSelector = (state) => state.entities.sources;
export const sourceLabelFormatter = (sources, id) =>
  sources?.[id]?.preferred_name;
export const useSourceLabelFormatter = (accessor) =>
  useFormatter(sourcesSelector, sourceLabelFormatter, accessor);

// Product hierarchy
export const productHierarchiesSelector = (state) =>
  state.entities.productHierarchies;
export const productHierarchyLabelFormatter = (productHierarchies, id) =>
  productHierarchies?.[id]?.full_name;
export const useProductHierarchyLabelFormatter = (accessor) =>
  useFormatter(
    productHierarchiesSelector,
    productHierarchyLabelFormatter,
    accessor
  );

// Product hierarchy group
export const productHierarchyGroupsSelector = (state) =>
  state.entities.productHierarchyGroups;
export const productHierarchyGroupLabelFormatter = (
  productHierarchyGroups,
  id
) => productHierarchyGroups?.[id]?.name;
export const useProductHierarchyGroupLabelFormatter = (accessor = identity) =>
  useFormatter(
    productHierarchyGroupsSelector,
    productHierarchyGroupLabelFormatter,
    accessor
  );
// Source groups
export const sourceGroupsSelector = (state) => state.entities.sourceGroups;
export const sourceGroupLabelFormatter = (sourceGroups, id) =>
  sourceGroups?.[id]?.name;
export const useSourceGroupLabelFormatter = (accessor = identity) =>
  useFormatter(sourceGroupsSelector, sourceGroupLabelFormatter, accessor);

// Tag fields

export const tagSetsSelector = (state) => state.entities.tagSets;
const tagSetsLabelFormatter = (tagSets, id) => tagSets?.[id]?.name;
export const tagSetGetter = (tagSets, id) => tagSets?.[id];

export const tagsSelector = (state) => state.entities.tags;
export const tagGetter = (tags, id) => tags?.[id];
export const tagsLabelFormatter = (tags, id) => tags?.[id]?.name;
export const tagsColorFormatter = (tags, id) => tags?.[id]?.color;

export const useTagSetGetter = (accessor = identity) =>
  useFormatter(tagSetsSelector, tagSetGetter, accessor);
export const useTagSetLabelFormatter = (accessor = identity) =>
  useFormatter(tagSetsSelector, tagSetsLabelFormatter, accessor);
export const useTagGetter = (accessor = identity) =>
  useFormatter(tagsSelector, tagGetter, accessor);
export const useTagLabelFormatter = (accessor = identity) =>
  useFormatter(tagsSelector, tagsLabelFormatter, accessor);

export const useUserOrTagLabelFormatter = (accessor = identity) =>
  useFormatter(
    createSelector(
      tagsSelector,
      (state) => state.entities?.users,
      (tags, users) => [tags, users]
    ),
    ([tags, users], id) =>
      tags?.[id]?.name ||
      users?.find((user) => user.id === id)?.pseudonym ||
      '-',
    accessor
  );
export const useTagColorFormatter = (accessor = identity) =>
  useFormatter(tagsSelector, tagsColorFormatter, accessor);

// Analysis
export const groupColorsSelector = (state) =>
  state.view.viewFacetAggregates[state.view.viewFacet?.id].colors;
export const groupColorFormatter = (colors, id) => colors?.[id] || '';
export const useGroupColorFormatter = (accessor = identity) =>
  useFormatter(groupColorsSelector, groupColorFormatter, accessor);

// Campaigns
export const campaignChannelsSelector = (campaignId) => (state) =>
  state.campaignChannels.campaignIdChannels[campaignId];

export const campaignChannelsItemsSelector = (campaignId) =>
  createSelector(
    campaignChannelsSelector(campaignId),
    makeDropdownItemsFromDbDocuments
  );

export const channelGetter = (channels, id) => channels?.[id];
export const channelsLabelFormatter = (channels, id) =>
  channels?.find(({ id: channelId }) => id === channelId)?.name;
export const useChannelLabelFormatter =
  (campaignId) =>
  (accessor = identity) =>
    useFormatter(
      campaignChannelsSelector(campaignId),
      channelsLabelFormatter,
      accessor
    );
