import { t } from '@lingui/macro';

import { createSelector } from '@reduxjs/toolkit';

import { removeHtmlTags } from 'utils/helpers';
import renderHtml from 'utils/html';

import {
  campaignConfigurationDiffSelectorFactory,
  campaignCustomizationSelectorFactory,
  campaignSelector,
} from './campaign';

const TEXT_SURVEY_QUESTION_TYPE = {
  key: 'TextSurveyQuestion',
  value: 'TextSurveyQuestion',
  text: t`text-survey-question`,
  icon: 'font',
};

const MULTIPLE_CHOICE_SURVEY_QUESTION_TYPE = {
  key: 'MultiChoiceSurveyQuestion',
  value: 'MultiChoiceSurveyQuestion',
  text: t`categorical-survey-question`,
  icon: 'list',
};
export const QUESTION_TYPES = [
  MULTIPLE_CHOICE_SURVEY_QUESTION_TYPE,
  TEXT_SURVEY_QUESTION_TYPE,
  { key: 'NPS', value: 'NPS', text: t`NPS`, disabled: true, icon: 'star' },
  { key: 'CSAT', value: 'CSAT', text: t`CSAT`, disabled: true, icon: 'star' },
  { key: 'CES', value: 'CES', text: t`CES`, disabled: true, icon: 'star' },
];
export const AUTO_NAVIGATE_SPEED_OPTIONS = [
  {
    value: 1,
    label: t`immediate`,
  },
  {
    value: 400,
    label: t`fast`,
  },
  {
    value: 900,
    label: t`slow`,
  },
];

export const getQuestionType = (questionType) =>
  QUESTION_TYPES.find(({ value }) => value === questionType);

const surveyCustomizationElementSelectorFactory =
  (key, elementKey = null, defaultValue = null) =>
  (campaignId, useDiff = false) =>
    createSelector(
      (useDiff ? campaignConfigurationDiffSelectorFactory : campaignSelector)(
        campaignId
      ),
      (configuration) => {
        const element = configuration?.[key] || defaultValue;
        if (elementKey) {
          return element?.[elementKey] || defaultValue;
        }
        return element;
      }
    );

export const surveyQuestionsSelector =
  surveyCustomizationElementSelectorFactory('questions', null, []);

export const displayHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('display_home_screen', null, false);

export const endScreenSelector = surveyCustomizationElementSelectorFactory(
  'end_screen',
  null,
  {}
);

export const taglineHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'tagline', '');
export const titleHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'title', '');

export const descriptionHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'description', '');

export const ctaTextHomeScreenSelector =
  surveyCustomizationElementSelectorFactory('home_screen', 'button_label', '');

export const titleEndScreenSelector = surveyCustomizationElementSelectorFactory(
  'end_screen',
  'title',
  ''
);
export const descriptionEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'description', '');
export const endScreenIllustrationSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'illustration', '');

export const showCtaEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'show_button', false);
export const ctaLabelEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'button_label', '');
export const ctaUrlEndScreenSelector =
  surveyCustomizationElementSelectorFactory('end_screen', 'button_url', '');

export const autoNavigateSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'auto_navigate',
  false
);
export const navigationSpeedSelector =
  surveyCustomizationElementSelectorFactory(
    'theme',
    'navigation_speed',
    AUTO_NAVIGATE_SPEED_OPTIONS[1].value
  );

export const primaryColorSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'primary',
  ''
);

export const mainLogoSelector = surveyCustomizationElementSelectorFactory(
  'logo_url',
  null,
  ''
);

export const previousLogoSelector = (campaignId) =>
  createSelector(
    campaignSelector(campaignId),
    (configuration) => configuration.logo_url
  );

export const isRelaxedSelector = surveyCustomizationElementSelectorFactory(
  'theme',
  'relaxed',
  false
);

export const dpoEmailSelector = surveyCustomizationElementSelectorFactory(
  'confidentiality',
  'dpo_email',
  ''
);
export const privacyPolicySelector = surveyCustomizationElementSelectorFactory(
  'confidentiality',
  'organization_policy_url',
  ''
);

export const questionSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions?.find(
        ({ id: questionUniqueId }) => questionUniqueId === questionId
      ) || null
  );

export const questionAnswerItemsSelector =
  (campaignId, useDiff = false) =>
  (questionId) =>
    createSelector(
      questionSelectorFactory(campaignId, questionId, useDiff),
      (question) =>
        question?.options?.map(({ id: iterQuestionId, display_name }) => ({
          key: iterQuestionId,
          value: iterQuestionId,
          content: display_name?.trim() ? renderHtml(display_name) : '-',
        })) || []
    );

export const conditionQuestionItemsSelectorFactory = (
  campaignId,
  questionId,
  useDiff = false,
  // Do not return text questions
  categoricalOnly = false,
  filterIds = []
) =>
  createSelector(surveyQuestionsSelector(campaignId, useDiff), (questions) => {
    const currentQuestionIndex = questions.findIndex(
      ({ id: questionUniqueId }) => questionUniqueId === questionId
    );
    const alreadyInConditions =
      questions[currentQuestionIndex]?.conditions?.map(
        ({ question }) => question
      ) || [];
    return (
      // Select only questions that are before the current question
      questions
        .slice(0, currentQuestionIndex)
        .filter(
          ({ id: questionUniqueId, type: questionType }) =>
            questionUniqueId &&
            !alreadyInConditions.includes(questionUniqueId) &&
            (!categoricalOnly ||
              !['TextSurveyQuestion'].includes(questionType)) &&
            !filterIds.includes(questionUniqueId)
        )
        .map(({ type, index, id, title }) => ({
          key: id,
          value: id,
          text: `${index}. ${title}`,
          content: renderHtml(title, getQuestionType(type)?.icon, `${index}. `),
        })) || []
    );
  });

export const isFeedbackCampaignConfiguration = (campaign) =>
  !!campaign?.ontology || false;

export const isFeedbackCampaignSelector = (campaignId) =>
  createSelector(campaignSelector(campaignId), isFeedbackCampaignConfiguration);

// Survey campaigns
export const surveyQuestionsItemsSelectorFactory = (
  campaignId,
  useDiff = false,
  noTextQuestion = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    (questions) =>
      questions
        ?.filter(
          (item) => !noTextQuestion || item.type !== 'TextSurveyQuestion'
        )
        .map(({ id, title, ...questionProps }, i) => ({
          // We use position as an alternative to id, since id does not exist at first when
          // question is created
          key: i,
          value: id,
          text: removeHtmlTags(`${i + 1}. ${title}`),
          tagSetId: questionProps.tag_set?.id,
        })) || []
  );

export const questionLabelFormatterSelectorFactory = (
  campaignId,
  useDiff = false
) =>
  createSelector(
    surveyQuestionsSelector(campaignId, useDiff),
    campaignSelector(campaignId),
    (questions) => (questionId) => {
      const questionIndex = questions?.findIndex(({ id }) => id === questionId);
      return questions?.[questionIndex]
        ? removeHtmlTags(
            `${questionIndex + 1}. ${questions[questionIndex].title}`
          )
        : '-';
    }
  );

const answerLabelsSelector = (campaignId) =>
  createSelector(campaignSelector(campaignId), (configuration) =>
    configuration.questions?.reduce((acc, question) => {
      question.options?.forEach((option) => {
        acc[option.id] = option;
      });
      return acc;
    }, {})
  );
export const questionAnswerLabelFormatterSelectorFactory = (campaignId) =>
  createSelector(
    answerLabelsSelector(campaignId),
    (answerLabels) => (cellKey) =>
      (typeof answerLabels[cellKey]?.display_name === 'string' &&
        renderHtml(answerLabels[cellKey]?.display_name)) ||
      cellKey?.map?.(
        (key) =>
          renderHtml(answerLabels[key]?.display_name, null, null, key) || '-'
      ) ||
      t`not-completed`
  );

export const newQuestionSelector = (campaignId) =>
  createSelector(
    campaignCustomizationSelectorFactory(campaignId),
    (customization) => customization?.newQuestion
  );
