import { createTransform, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import campaign from 'reducers/campaign';
import entities from 'reducers/entities';
import facet from 'reducers/facet';
import home from 'reducers/home';
import locale, { getInitialLanguage } from 'reducers/locale';
import operations from 'reducers/operations';
import search from 'reducers/search';
import ui, { loadingReducer } from 'reducers/ui';
import user from 'reducers/user';
import validation from 'reducers/validation';
import view from 'reducers/view';
import campaignPreset from 'store/monitor/campaignPresetSlice';
import campaignChannels from 'store/monitor/channelsSlice';
import campaignSearch from 'store/monitor/monitorSearchSlice';
import campaignMonitor from 'store/monitor/monitorSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const loadLocaleTransform = createTransform(
  null,
  // transform state being rehydrated
  (previousStateValue) => getInitialLanguage(previousStateValue || 'fr'),
  { whitelist: ['language'] }
);

export default {
  ui,
  locale: persistReducer(
    { ...persistConfig, transforms: [loadLocaleTransform] },
    locale
  ),
  user: persistReducer(persistConfig, user),
  validation,
  loading: loadingReducer,
  view,
  facet,
  entities,
  home,
  operations,
  search,
  campaign,
  campaignMonitor,
  campaignSearch,
  campaignPreset,
  campaignChannels,
};
