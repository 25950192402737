import { createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { api } from 'actions/utils';
import { EMPTY_CATEGORY_LABEL } from 'reducers/entityLabelFormatter';

import { StateStatus, createAPISlice } from 'utils/apiSlice';

import {
  campaignFiltersSelector,
  toggleFiltersPaneAndMaybeFetchFeedback,
  updateFiltersFromPreset,
} from './monitorSearchSlice';

// Thunks

export const maybeLoadCampaignFilterPresets = createAsyncThunk(
  'campaignPreset/maybeLoadCampaignFilterPresets:load',
  async ({ campaignId }, { getState }) => {
    // Do not fetch if already loaded
    const { presets } = getState().campaignPreset;
    if (presets[campaignId]) {
      return {};
    }

    const response = await api.get(`/campaign-review/${campaignId}/preset`);
    return { campaignId, presets: response.data };
  }
);

export const createCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/createCampaignFilterPreset:load',
  async (
    {
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency,
    },
    { getState }
  ) => {
    // Get date filters from state
    const { period, customRangeDates } = getState().campaignSearch;
    const payload = {
      name: presetName,
      date_period: period,
      ...filtersValues,
    };
    if (period === 'custom') {
      payload.min_date = customRangeDates.minDate;
      payload.max_date = customRangeDates.maxDate;
    }
    if (hasNotification) {
      payload.notification = { frequency: notificationFrequency };
    }
    const response = await api.post(
      `/campaign-review/${campaignId}/preset`,
      payload
    );
    return { campaignId, preset: response.data };
  }
);

export const updateCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/updateCampaignFilterPreset:load',
  async (
    {
      id,
      campaignId,
      presetName,
      filtersValues,
      hasNotification,
      notificationFrequency,
    },
    { getState }
  ) => {
    // Get date filters from state
    const { period, customRangeDates } = getState().campaignSearch;
    const payload = {
      name: presetName,
      date_period: period,
      ...filtersValues,
    };
    if (period === 'custom') {
      payload.min_date = customRangeDates.minDate;
      payload.max_date = customRangeDates.maxDate;
    }
    if (hasNotification) {
      payload.notification = { frequency: notificationFrequency };
    }
    const response = await api.put(
      `/campaign-review/${campaignId}/preset/${id}`,
      payload
    );
    return { campaignId, preset: response.data };
  }
);

export const deleteCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/deleteCampaignFilterPreset:load',
  async ({ campaignId, presetId }, { getState }) => {
    await api.delete(`/campaign-review/${campaignId}/preset/${presetId}`);
    return { campaignId, presetId };
  }
);

export const setCampaignFilterPreset = createAsyncThunk(
  'campaignPreset/setCampaignFilterPreset:load',
  async ({ campaignId, presetId }, { getState, dispatch }) => {
    const preset = getState().campaignPreset.presets[campaignId]?.find(
      ({ id }) => id === presetId
    );
    const filters = { tags: {} };
    if (preset.channels?.length) {
      filters.channels = preset.channels.map(({ id }) => id);
    }
    if (preset.db_concepts?.length) {
      filters.concepts = preset.db_concepts.map(({ id }) => id);
    }
    if (preset.answers?.length) {
      filters.answers = {};

      preset.answers.forEach(({ question: { id: questionId }, value }) => {
        filters.answers[questionId] = value?.map(({ id }) => id);
      });
    }
    ['annotation_values', 'response_values', 'url_values'].forEach(
      (tagFieldName) => {
        if (preset[tagFieldName]?.length) {
          filters.tags[tagFieldName] = {};
          preset[tagFieldName].forEach(
            ({ type, tags, users, form_element: { id: formElementId } }) => {
              if (!filters.tags[tagFieldName][formElementId]) {
                filters.tags[tagFieldName][formElementId] = [];
              }
              if (type === 'EmptyFilter') {
                filters.tags[tagFieldName][formElementId].push(
                  EMPTY_CATEGORY_LABEL
                );
                return;
              }
              filters.tags[tagFieldName][formElementId] = [
                ...filters.tags[tagFieldName][formElementId],
                ...((tags || users)?.map(
                  ({ id }) => id || EMPTY_CATEGORY_LABEL
                ) || []),
              ];
            }
          );
        }
      }
    );
    if (preset.satisfaction_tags?.length) {
      filters.tags.satisfaction_tag = preset.satisfaction_tags.map(
        ({ id }) => id
      );
    }
    if (filters) {
      await dispatch(
        updateFiltersFromPreset({
          period: preset.date_period,
          customRangeDates: {
            minDate: preset.min_date,
            maxDate: preset.max_date,
          },
          filters,
        })
      );
      await dispatch(
        toggleFiltersPaneAndMaybeFetchFeedback({
          campaignId,
          forceUpdate: true,
        })
      );
    }
    return { campaignId, presetId };
  }
);

const campaignSearchSlice = createAPISlice(
  {
    name: 'campaignPreset',
    initialState: {
      // Map campaign id to filter values
      presets: {},
      selectedPreset: null,
      editPreset: null,
    },
    reducers: {
      setEditPreset: (state, { payload }) => {
        state.editPreset = payload;
      },
      resetPresetSelection: (state) => {
        state.selectedPreset = null;
        state.editPreset = null;
      },
    },
    extraReducers: (builder) => {
      builder.addCase(
        maybeLoadCampaignFilterPresets.fulfilled,
        (state, { payload: { campaignId, presets } }) => {
          if (presets) state.presets[campaignId] = presets;
        }
      );
      builder.addCase(
        createCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, preset } }) => {
          state.presets[campaignId] = [...state.presets[campaignId], preset];
          state.selectedPreset = preset;
        }
      );
      builder.addCase(
        updateCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, preset } }) => {
          const toUpdateIndex = state.presets[campaignId].findIndex(
            ({ id }) => id === preset.id
          );
          state.presets[campaignId] = [
            ...state.presets[campaignId].slice(0, toUpdateIndex),
            preset,
            ...state.presets[campaignId].slice(toUpdateIndex + 1),
          ];
          state.selectedPreset = preset;
        }
      );
      builder.addCase(
        deleteCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, presetId } }) => {
          const toRemoveIndex = state.presets[campaignId].findIndex(
            ({ id }) => id === presetId
          );
          state.presets[campaignId] = [
            ...state.presets[campaignId].slice(0, toRemoveIndex),
            ...state.presets[campaignId].slice(toRemoveIndex + 1),
          ];

          if (state.selectedPreset?.id === presetId) {
            state.selectedPreset = null;
          }
          if (state.editPreset?.id === presetId) {
            state.editPreset = null;
          }
        }
      );
      builder.addCase(
        setCampaignFilterPreset.fulfilled,
        (state, { payload: { campaignId, presetId } }) => {
          state.selectedPreset = state.presets[campaignId].find(
            ({ id }) => id === presetId
          );
        }
      );
    },
  },
  {
    keys: [
      'maybeLoadCampaignFilterPresets',
      'createCampaignFilterPreset',
      'deleteCampaignFilterPreset',
      'updateCampaignFilterPreset',
    ],
  }
);

export const { setEditPreset, resetPresetSelection } =
  campaignSearchSlice.actions;

export default campaignSearchSlice.reducer;

// Selectors
export const campaignPresetsSelectorFactory = (campaignId) => (state) =>
  state.campaignPreset.presets[campaignId] || [];

export const campaignPresetsLoadingSelector = (state) =>
  state.campaignPreset.maybeLoadCampaignFilterPresets === StateStatus.PENDING;

export const saveCampaignPresetLoadingSelector = (state) =>
  state.campaignPreset.createCampaignFilterPreset === StateStatus.PENDING;

export const selectedPresetSelector = (state) =>
  state.campaignPreset.selectedPreset;

export const editPresetSelector = (state) => state.campaignPreset.editPreset;

export const isPresetNameAvailableSelectorFactory = (campaignId) =>
  createSelector(
    campaignPresetsSelectorFactory(campaignId),
    (presets) => (presetName) =>
      !presets.some(({ name }) => name === presetName)
  );

export const campaignPresetFiltersAreEmptySelector = createSelector(
  campaignFiltersSelector,
  (filters) =>
    !filters.channels?.length &&
    !filters.ontologyConcepts?.length &&
    !filters.concepts?.length &&
    !(
      filters.tags?.satisfaction_tag && filters.tags?.satisfaction_tag.length
    ) &&
    !(
      filters.tags?.url_values && Object.keys(filters.tags?.url_values).length
    ) &&
    !(
      filters.tags?.annotation_values &&
      Object.keys(filters.tags?.annotation_values).length
    ) &&
    !(
      filters.tags?.respondent_values &&
      Object.keys(filters.tags?.respondent_values).length
    ) &&
    !(filters.answers && Object.keys(filters.answers).length)
);
