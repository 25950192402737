import PropTypes from 'prop-types';

const commonPropTypes = {};

// Basics - augment proptypes
commonPropTypes.style = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number])
);

commonPropTypes.component = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
  PropTypes.shape({ render: PropTypes.func.isRequired }),
]);

commonPropTypes.items = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    id: PropTypes.string,
    text: PropTypes.string,
  })
);

// Native js date (use with date-fns, should deprecate usage of moment)
commonPropTypes.date = PropTypes.shape({ toLocaleDateString: PropTypes.func });

// Application configuration
commonPropTypes.appConfig = PropTypes.shape({
  SHOW_ORIGINAL_TEXTS: PropTypes.bool,
});

// Locale
commonPropTypes.language = PropTypes.shape({
  value: PropTypes.string,
  flag: PropTypes.string,
  text: PropTypes.string,
});
commonPropTypes.languages = PropTypes.arrayOf(commonPropTypes.language);

commonPropTypes.i18nText = PropTypes.string;

commonPropTypes.i18n = PropTypes.shape({ _: PropTypes.func.isRequired });

// Entities

commonPropTypes.concepts = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  fullName: PropTypes.string,
  level: PropTypes.number,
  parent: PropTypes.shape({ id: PropTypes.string }),
  parentIds: PropTypes.arrayOf(PropTypes.string),
});

commonPropTypes.ontologyItem = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  display_name: PropTypes.string,
  concepts: PropTypes.arrayOf(commonPropTypes.concepts),
});
commonPropTypes.ontology = PropTypes.objectOf(commonPropTypes.ontologyItem);
commonPropTypes.ontologies = PropTypes.objectOf(commonPropTypes.ontology);
commonPropTypes.validation_ontologies = PropTypes.arrayOf(
  commonPropTypes.ontologyItem
);
commonPropTypes.productHierarchy = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  full_name: PropTypes.string,
  hierarchy_type: PropTypes.string,
});

commonPropTypes.productHierarchyItem = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  parent: PropTypes.string,
  label: PropTypes.string.isRequired,
});
commonPropTypes.dropdownItems = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  label: PropTypes.string.isRequired,
});

commonPropTypes.source = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  preferred_name: PropTypes.string,
});

commonPropTypes.productHierarchyGroup = PropTypes.shape({
  create_date: PropTypes.string,
  update_date: PropTypes.string,
  name: PropTypes.string,
  priority: PropTypes.number,
  n_view_facets: PropTypes.number,
  id: PropTypes.string,
  items: PropTypes.arrayOf(commonPropTypes.productHierarchy),
});

commonPropTypes.ontologyLabels = PropTypes.objectOf(
  PropTypes.shape({
    label: PropTypes.string,
    color: PropTypes.string,
  })
);

commonPropTypes.entities = PropTypes.shape({
  ontologies: commonPropTypes.ontologies.isRequired,
  productHierarchies: PropTypes.objectOf(commonPropTypes.productHierarchy),
  productHierarchyGroups: PropTypes.objectOf(
    commonPropTypes.productHierarchyGroup
  ),
  sources: PropTypes.objectOf(commonPropTypes.source),
  ontologyLabels: commonPropTypes.ontologyLabels.isRequired,
});

// App & permissions

commonPropTypes.view = PropTypes.shape({
  name: PropTypes.string,
  // configuration: PropTypes.object,
});

// Source and product hierarchy groups
commonPropTypes.entityGroup = PropTypes.shape({
  id: PropTypes.string,
  create_date: PropTypes.string,
});
commonPropTypes.viewFacet = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  base_product_hierarchy_group: commonPropTypes.entityGroup,
  comparative_product_hierarchy_groups: PropTypes.arrayOf(
    commonPropTypes.entityGroup
  ),
  source_group: commonPropTypes.entityGroup,
});

// Reviews
commonPropTypes.review = PropTypes.shape({
  texts: PropTypes.arrayOf(PropTypes.string),
  positive_points: PropTypes.arrayOf(PropTypes.string),
  negative_points: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  is_url_public: PropTypes.bool,
  external_id: PropTypes.string,
  source: PropTypes.shape({
    domain: PropTypes.string,
    name: PropTypes.string,
  }),
  brand: PropTypes.shape({
    name: PropTypes.string,
  }),
  product: PropTypes.shape({
    name: PropTypes.string,
  }),
  generation: PropTypes.shape({
    name: PropTypes.string,
  }),
  format: PropTypes.shape({
    name: PropTypes.string,
  }),
  technical_specification: PropTypes.shape({
    name: PropTypes.string,
  }),
});

commonPropTypes.itemsClassifications = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.shape({
      ontology_name: PropTypes.string,
      db_concept: PropTypes.shape({ id: PropTypes.string }),
    })
  )
);

commonPropTypes.classifications = PropTypes.objectOf(
  PropTypes.shape({
    id: PropTypes.string,
    items_texts: commonPropTypes.itemsClassifications,
    items_positive: commonPropTypes.itemsClassifications,
    items_negative: commonPropTypes.itemsClassifications,
  })
);

// Aggregates
commonPropTypes.timeSeriesItem = PropTypes.shape({
  average_sentiment: PropTypes.number,
  db_concept: PropTypes.string,
  cumulated: PropTypes.number,
  date: PropTypes.string,
  n_chunks: PropTypes.number,
  negative: PropTypes.number,
  positive: PropTypes.number,
  rolling_average_sentiment: PropTypes.number,
});
commonPropTypes.timeSeries = PropTypes.arrayOf(commonPropTypes.timeSeriesItem);

commonPropTypes.kpiPropTypes = PropTypes.shape({
  volume: PropTypes.number,
  average_sentiment: PropTypes.number,
  std_sentiment: PropTypes.number,
});

const distributionItemProps = {
  n_chunks: PropTypes.number,
  average_sentiment: PropTypes.number,
};

commonPropTypes.sourceDistributionItem = PropTypes.shape({
  ...distributionItemProps,
  source: PropTypes.string,
});
commonPropTypes.ontologyDistributionItem = PropTypes.shape({
  ...distributionItemProps,
  db_ontology: PropTypes.string,
});
commonPropTypes.productHierarchyDistributionItem = PropTypes.shape({
  ...distributionItemProps,
  product_hierarchy: PropTypes.string,
});

commonPropTypes.kpis = PropTypes.shape({
  kpis: PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem),
  increases: PropTypes.objectOf(
    PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem)
  ),
});
const benchmarkKpiPropTypes = {
  average_sentiment_differential: PropTypes.number,
  competition_n_chunks: PropTypes.number,
};

commonPropTypes.competitionKpis = PropTypes.shape({
  benchmarkKpis: PropTypes.arrayOf(PropTypes.shape(benchmarkKpiPropTypes)),
  kpis: PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem),
  increases: PropTypes.objectOf(
    PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem)
  ),
});
commonPropTypes.conceptKpis = PropTypes.objectOf(
  PropTypes.objectOf(
    PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem)
  )
);

commonPropTypes.topBottomBenchmarkItem = PropTypes.shape({
  ...benchmarkKpiPropTypes,
  db_concept: PropTypes.string,
});

commonPropTypes.competitionConceptKpis = PropTypes.shape({
  kpis: PropTypes.arrayOf(commonPropTypes.productHierarchyDistributionItem),
  top: PropTypes.arrayOf(commonPropTypes.topBottomBenchmarkItem),
  bottom: PropTypes.arrayOf(commonPropTypes.topBottomBenchmarkItem),
  benchmark_kpis: PropTypes.objectOf(
    PropTypes.objectOf(
      PropTypes.arrayOf(commonPropTypes.topBottomBenchmarkItem)
    )
  ),
});

commonPropTypes.timeSeriesVisualizations = PropTypes.shape({
  kpis: commonPropTypes.kpis,
  competitionKpis: commonPropTypes.competitionKpis,
  conceptKpis: commonPropTypes.conceptKpis,
  competitionConceptKpis: commonPropTypes.competitionConceptKpis,
  competitionTimeSeries: PropTypes.shape({
    monthly: PropTypes.objectOf(commonPropTypes.timeSeries),
  }),
  competitionConceptTimeSeries: PropTypes.shape({
    // keys respectively : product hierarchy id - db_concept
    monthly: PropTypes.objectOf(
      PropTypes.objectOf(PropTypes.objectOf(commonPropTypes.timeSeries))
    ),
  }),

  generalDistributions: PropTypes.shape({
    ontology: PropTypes.arrayOf(commonPropTypes.ontologyDistributionItem),
    source: PropTypes.arrayOf(commonPropTypes.sourceDistributionItem),
  }),
  timeSeries: PropTypes.shape({
    monthly: commonPropTypes.timeSeries,
  }),
});

// Wordcloud
commonPropTypes.word = PropTypes.shape({
  text: PropTypes.string,
  n_chunks: PropTypes.number,
  value: PropTypes.number,
  sentiment: PropTypes.number,
  coocurrence: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      n_chunks: PropTypes.number,
      sentiment: PropTypes.number,
    })
  ),
});

commonPropTypes.wordClouds = PropTypes.objectOf(
  PropTypes.shape({
    vocabulary: PropTypes.shape({
      positive: PropTypes.arrayOf(commonPropTypes.word),
      neagtive: PropTypes.arrayOf(commonPropTypes.word),
      all: PropTypes.arrayOf(commonPropTypes.word),
    }),
    sample_size: PropTypes.number,
  })
);

// Search
commonPropTypes.searchResultsStatistics = PropTypes.shape({
  n_chunks: PropTypes.number,
  average_sentiment: PropTypes.number,
});
commonPropTypes.searchResultsReviewChunks = PropTypes.arrayOf(
  PropTypes.shape({})
);
commonPropTypes.searchResultsPagination = PropTypes.shape({
  text: PropTypes.string,
});
commonPropTypes.searchResults = PropTypes.shape({
  reviewChunks: commonPropTypes.searchResultsReviewChunks,
  statistics: commonPropTypes.searchResultsStatistics,
  pagination: commonPropTypes.searchResultsPagination,
});
commonPropTypes.searchFilterValues = PropTypes.shape({
  sources: PropTypes.arrayOf(PropTypes.string),
  productHierarchies: PropTypes.arrayOf(PropTypes.string),
  productHierarchyGroups: PropTypes.arrayOf(PropTypes.string),
  matchAllConcepts: PropTypes.bool,
  ontologyConcepts: PropTypes.arrayOf(
    PropTypes.shape({
      ontologyName: PropTypes.string,
      conceptId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ),
  polarities: PropTypes.arrayOf(PropTypes.string),
  textSearchValues: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  page: PropTypes.number,
  pageSize: PropTypes.number,
  sortDateAsc: PropTypes.bool,
});
commonPropTypes.formElementValue = PropTypes.shape({
  tag: PropTypes.shape({ id: PropTypes.string }),
  form_element: PropTypes.shape({ id: PropTypes.string }),
});

// Filter presets
commonPropTypes.filterPreset = PropTypes.shape({
  sources: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
  product_hierarchies: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string })
  ),
  hierarchy_groups: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string })
  ),
  concepts_match_all: PropTypes.bool,
  db_concepts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    })
  ),
  positive: PropTypes.bool,
  negative: PropTypes.bool,
  neutral: PropTypes.bool,
  texts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  min_date: PropTypes.string,
  max_date: PropTypes.string,
});
commonPropTypes.filterPresets = PropTypes.arrayOf(commonPropTypes.filterPreset);

commonPropTypes.controllerParameter = PropTypes.shape({
  default: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.array,
  ]),
  name: PropTypes.string,
  help: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
});
commonPropTypes.controller = PropTypes.shape({
  name: PropTypes.string,
  help: PropTypes.arrayOf(PropTypes.string),
  parameters: PropTypes.arrayOf(commonPropTypes.controllerParameter),
  no_dry_run: PropTypes.bool,
});
commonPropTypes.controllers = PropTypes.objectOf(
  PropTypes.shape({
    controllers: PropTypes.arrayOf(commonPropTypes.controller),
    help: PropTypes.string,
  })
);
commonPropTypes.modules = PropTypes.arrayOf(
  PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
    text: PropTypes.string,
    subText: PropTypes.string,
  })
);

commonPropTypes.task = PropTypes.shape({
  celery_id: PropTypes.string,
  controller_name: PropTypes.string,
  create_date: PropTypes.string,
  dry_run: PropTypes.bool,
  end: PropTypes.string,
  id: PropTypes.string,
  parameters: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
  ),
  report: PropTypes.string,
  start: PropTypes.string,
  status: PropTypes.string,
});
commonPropTypes.tasks = PropTypes.arrayOf(commonPropTypes.task);

commonPropTypes.uiFields = PropTypes.shape({});

commonPropTypes.tagField = PropTypes.shape({
  Cell: PropTypes.elementType,
  accessor: PropTypes.func,
  centered: PropTypes.bool,
  feedbackFormValuesFieldName: PropTypes.string,
  getCellProps: PropTypes.func,
  header: PropTypes.string,
  id: PropTypes.string,
  key: PropTypes.string,
  label: PropTypes.string,
  maxWidth: PropTypes.number,
  sortType: PropTypes.string,
  tagSetId: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});
commonPropTypes.tagFields = PropTypes.arrayOf(commonPropTypes.tagField);

// Dashboard Element chart

commonPropTypes.dashboardChart = {
  chartId: PropTypes.string,
  height: PropTypes.number.isRequired,
  noDataMessage: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape({
    cellKey: PropTypes.string,
    entityKey: PropTypes.string,
    indicatorKey: PropTypes.string,
    minColor: PropTypes.string,
    maxColor: PropTypes.string,
  }),
};
commonPropTypes.channel = PropTypes.shape({ name: PropTypes.string });

// Survey

commonPropTypes.condition = PropTypes.shape({
  question: PropTypes.shape({ id: PropTypes.string }),
  values: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
});
commonPropTypes.question = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  section_name: PropTypes.string,
  type: PropTypes.string,
  multiple: PropTypes.bool,
  randomize: PropTypes.bool,
  required: PropTypes.bool,
  vertical: PropTypes.bool,
  conditions: PropTypes.arrayOf(commonPropTypes.condition),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      text: PropTypes.string,
    })
  ),
});

commonPropTypes.campaignPreset = PropTypes.shape({
  name: PropTypes.string,
  notification: PropTypes.shape({
    frequency: PropTypes.string,
  }),
});

export default commonPropTypes;
