import { api } from 'actions/utils';

const uploadCampaignLogo = async (campaignId, file, callback) => {
  let response;
  const formData = new FormData();
  formData.append('file', file);

  try {
    response = await api.post(
      `/campaign/${campaignId}/upload_asset`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  } catch (error) {
    return callback(false, null);
  }

  return callback(true, response.data.file_url);
};

export default uploadCampaignLogo;
